import React from "react";

import triangle from '../../../static/images/case-studies/triangle-bullet.svg';
import subTriangle from '../../../static/images/case-studies/triangle-bullet-outline.svg';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenWidthLimit: 600,
      windowWidth: 1000,
      features: [],
      leftFeatures: [],
      rightFeatures: []
    }
    this.determineLeftAndRightArrays = this.determineLeftAndRightArrays.bind(this);
    this.redefineFeatureArray = this.redefineFeatureArray.bind(this);
    this.divideArrayEqually = this.divideArrayEqually.bind(this);
  }

  componentDidMount() {
    this.redefineFeatureArray();

    window.addEventListener('resize', () => {
      let e = window, a = 'inner';
      if (!('innerWidth' in window)) {
        a = 'client';
        e = document.documentElement || document.body;
      }

      const windowWidth = e[a + 'Width'];
      this.setState({
        windowWidth
      });
    });
  }

  divideArrayEqually(array) {
    return Math.ceil(array.length / 2);
  }

  determineLeftAndRightArrays(newFeaturesArray) {
    const arrayHalfMarker = this.divideArrayEqually(newFeaturesArray);
    let leftFeat = newFeaturesArray.slice(0, arrayHalfMarker);
    let rightFeat = newFeaturesArray.slice(arrayHalfMarker);

    this.setState({
      leftFeatures: leftFeat,
      rightFeatures: rightFeat
    })
  }

  redefineFeatureArray() {
    let newFeaturesArray = [];
    const mappedFeatures = this.props.caseStudyFeatures.map(item => 
      ({ point: item.point, parentFeature: item.parentFeature, order: item.order })
    );

    const rootPoints = mappedFeatures.filter((x) => !x.parentFeature).sort((a,b) => a < b ? -1 : 1);
    rootPoints.forEach((rootPoint) => {
      newFeaturesArray.push(rootPoint);
      const childFeatures = mappedFeatures.filter((x) => x?.parentFeature?.point === rootPoint.point);
      newFeaturesArray = [...newFeaturesArray, ...childFeatures];
    })

    this.setState({
      features: newFeaturesArray
    });

    this.determineLeftAndRightArrays(newFeaturesArray);
  }

  render() {
    return (
      <section>
        {
          this.windowWidth < this.state.screenWidthLimit ?
            (
              <div className="bullets padding-t-4">
                <div>
                  {this.state.features.map((x, i) => (
                    x.parentFeature === null ? (
                      <div key={i + 'feat'} >
                        <div className=" margin-r-2 margin-b-3">
                          <div className="feature-wrapper">
                            <img className="triangle-img" alt="triangular bullet point" src={triangle} />
                            <div className="bullets">
                              {x.point}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div key={i + 'subFeature'} className="margin-b-3 margin-r-2 margin-l-4">
                        <div className="feature-wrapper">
                          <img className="sub-triangle-img" alt="smaller triangular bullet point" src={subTriangle} />
                          <div className="bullets">
                            {x.point}
                          </div>
                        </div>
                      </div>
                    )
                  )
                  )}
                </div>
              </div>
            ) :
            (
              <div className="bullets padding-t-4">
                <div>
                  {this.state.leftFeatures.map((x, i) => (
                    x.parentFeature === null ? (
                      <div key={i + 'feat'}>
                        <div className=" margin-r-2 margin-b-3">
                          <div className="feature-wrapper">
                            <img className="triangle-img" alt="triangular bullet point" src={triangle} />
                            <div className="bullets">
                              {x.point}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div key={i + 'subFeature'} className="margin-b-3 margin-r-2 margin-l-4">
                        <div className="feature-wrapper">
                          <img className="sub-triangle-img" alt="smaller triangular bullet point" src={subTriangle} />
                          <div className="bullets">
                            {x.point}
                          </div>
                        </div>
                      </div>
                    )
                  )
                  )}
                </div>
                <div>
                  {this.state.rightFeatures.map((x, i) => (
                    x.parentFeature === null ? (
                      <div key={i + 'feat'}>
                        <div className=" margin-r-2 margin-b-3">
                          <div className="feature-wrapper">
                            <img className="triangle-img" alt="triangular bullet point" src={triangle} />
                            <div className="bullets">
                              {x.point}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div key={i + 'subFeature'} className="margin-b-3 margin-r-2 margin-l-4">
                        <div className="feature-wrapper">
                          <img className="sub-triangle-img" alt="smaller triangular bullet point" src={subTriangle} />
                          <div className="bullets">
                            {x.point}
                          </div>
                        </div>
                      </div>
                    )
                  )
                  )}
                </div>
              </div>
            )
        }
      </section>
    )
  }
}
