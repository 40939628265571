import React, { Component } from 'react';
import classNames from 'classnames';
import { submitContact } from "../../services/api.service";
import "./form.scss";
import obBrochure from "../../../static/images/contact/8-22-22-OB-Brochure.pdf"
import { getStaticContentByIdentifier } from "../../services/misc.service";

var opt = false;
class Form extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showFormError: false,
      showFormSuccess: false,
      contactForm: {
        email: '',
        name: '',
        company: '',
        website: '',
        message: '',
      },
      submitting: false,
      isDisabled: this.checkDisabled(),
      button: null
    };

    this.formRef = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitContactForm = this.submitContactForm.bind(this);
  }

  componentDidMount() {
    this.setState({
      button: document.querySelector('button[type="submit"]')
    })
  }

  handleInputChange(event) {
    const target = event.currentTarget;
    const value = target.value;
    const name = target.name;
    // If checkbox, flip. Otherwise update field.
    if (name == "optin") {
      opt = !opt;
    }
    else {
      this.setState(prevState => ({
        ...prevState,
        contactForm: {
          ...prevState.contactForm,
          [name]: value,
        },
        isDisabled: this.checkDisabled()
      }));
    }
  }

  checkDisabled() {
    setTimeout(() => {
      if(!this.state.button) return
      if (this.state.contactForm.name !== '' && this.state.contactForm.email !== '' && this.state.contactForm.message !== '') {
        this.state.button.setAttribute("tabIndex", "8")
        return false
      }
      this.state.button.removeAttribute("tabIndex")
      return true
    }, 0);
  }

  async submitContactForm(event) {
    if (!this.formRef || this.formRef.invalid) {
      return;
    }

    this.setState({
      showFormError: false,
      showFormSuccess: false,
    });

    event.preventDefault();
    event.stopPropagation();
    try {
      await this.setState({ submitting: true });
      const res = await submitContact(this.state.contactForm);
      if (res && res.successful) {
        this.setState({
          showFormSuccess: true,
          contactForm: {
            email: '',
            name: '',
            company: '',
            website: '',
            message: '',
          }
        });
      } else {
        this.setState({
          showFormError: true,
        });
      }
    } catch (_) {
      await this.setState({
        showFormError: true,
      });
    } finally {
      await this.setState({ submitting: false });
      // Check for opt in, then provide brochure if true
      if (opt === true) {
        window.open(obBrochure, '_blank');
      }
    }
  }

  render() {
    return (
      <section className={!this.props.outside ? 'bg-gradient' : ''}>
        <form ref={this.formRef} id="contact_form" className={this.props.outside ? 'outside padding-2' : 'padding-2'} onSubmit={this.submitContactForm}>
          <div className="row center">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <label className="contact-form-label left" htmlFor="name">Name<span className="required-asterisk">*</span></label>
              <input
                className="contact-form-input margin-b-1"
                value={this.state.contactForm.name}
                onChange={this.handleInputChange}
                type="text"
                required
                tabIndex="1"
                id="name"
                name="name" />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6">
              <label className="contact-form-label left" htmlFor="email">Email Address<span className="required-asterisk">*</span></label>
              <input
                className="contact-form-input margin-b-1"
                value={this.state.contactForm.email}
                onChange={this.handleInputChange}
                type="email"
                tabIndex="3"
                id="email"
                name="email"
                required />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <label className="contact-form-label left" htmlFor="company">Company Name</label>
              <input
                className="contact-form-input margin-b-1"
                value={this.state.contactForm.company}
                onChange={this.handleInputChange}
                type="text"
                tabIndex="4"
                id="company"
                name="company" />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6">
              <label className="contact-form-label left" htmlFor="website">Website URL</label>
              <input
                className="contact-form-input margin-b-1"
                value={this.state.contactForm.website}
                onChange={this.handleInputChange}
                type="text"
                tabIndex="5"
                id="website"
                name="website" />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-xs-12 col-md-12">
              <label className="contact-form-label left" htmlFor="message">What challenges can we help you with?<span className="required-asterisk">*</span></label>
              <textarea
                className="contact-form-text-area margin-b-1"
                value={this.state.contactForm.message}
                onChange={this.handleInputChange}
                tabIndex="6"
                id="message"
                name="message"
                required />
            </div>
          </div>
          <div className={!this.props.outside ? 'row checkrow' : 'row checkrow-outside'}>
            <input className={!this.props.outside ? 'opt-in' : 'opt-in-outside'} tabIndex="7" type="checkbox" id="optin" name="optin" onChange={this.handleInputChange} value={opt} />
            <label className={!this.props.outside ? 'opt-label' : 'opt-label-outside'} htmlFor="optin">I would like to open a brochure in a new tab containing more information regarding Orange Bees, how they operate and the services they offer.</label>
          </div>

          <div className={!this.props.outside ? 'margin-b-5' : 'margin-b-3'}>
            <button className="btn-center margin-b-0 text-uppercase" type="submit" disabled={this.state.submitting} >
              {this.state.submitting ? 'Submitting...' : 'Submit'}
            </button>
          </div>
          <div className="padding-l-3 padding-r-3 text-center">
            <p className={
              classNames({
                'form-error': true,
                show: this.state.showFormError,
              })
            }>
              There as an error submitting the form. Please reach out to us directly with the contact information above.
            </p>
            <p className={
              classNames({
                'form-success': true,
                show: this.state.showFormSuccess,
              })
            }>
              Thank you for your message! A team member will be in touch with you soon.
            </p>
          </div>
        </form>
      </section>
    )
  }
}

export default Form