import React from "react";

export const CaseStudyStatCards = ({ caseStudyStatCards }) => {

  return (
    <>
      <div className="stats margin-t-4">
        {
          caseStudyStatCards.map((x, i) =>
          (
            <div key={i} className="text-center margin-t-4">
              <div className="icon">
                <img src={process.env.filesBaseUrl + x.icon.publicUrl} alt="icon" />
              </div>
              <div className="stat">{x.number}</div>
              <div className="title">{x.title}</div>
              <div className="description">{x.description}</div>
            </div>
          )
          )
        }
      </div>
    </>
  )
}