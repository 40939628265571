const apiUrl = process.env.apiUrl;
const apiAuthKey = process.env.apiAuthKey;

const submitContact = async (contact) => {
  const res = await fetch(`${apiUrl}/contact/submit`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'orangebees-authkey': apiAuthKey
    },
    body: JSON.stringify(contact),
  });

  return res.json();
};

export { submitContact };