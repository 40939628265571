import "./resultsHero.scss"

import React from "react"

export default (props) => {
  const resultHeroMainStyle = { 
    background: `url(${props.resultsImage}) center no-repeat`,
    backgroundSize: 'contain',
    zIndex: '1',
    filter: 'blur(0)',
    position: 'relative',
    maxWidth: '1300px',
    height: '800px',
    margin: '0 auto'
  };

  const resultsHeroBgStyle = {
    background:`linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3) ),  url(${props.resultsImage}) center no-repeat`,
    backgroundSize: 'auto,cover',
    filter: 'blur(15px)',
    width:'100%',
    height:'800px',
    position: 'absolute',
    zIndex: '1',
  };

  return (
    <section className="case-study-results-hero margin-t-6 margin-b-0">
      <div className="blur-img" style={resultsHeroBgStyle}></div>
      <div style={resultHeroMainStyle}></div>
    </section>
  )
}