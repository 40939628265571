import React from 'react';
import { Helmet } from 'react-helmet'
import favicon from '../../static/favicon.png'

const filesBaseUrl = process.env.filesBaseUrl;

export default ({ SEOInfo, lang }) => (
    <Helmet
        htmlAttributes={{
            lang: lang ? lang : 'en',
        }}
        title={SEOInfo.title}
        meta={[
          { name: 'description', content: SEOInfo.description},
          { name: 'keywords', content: SEOInfo.keywords},
          { property: 'og:image', content: filesBaseUrl+SEOInfo.image.publicUrl},
          { property: 'og:title', content: SEOInfo.title},
          { property: 'og:url', content: SEOInfo.url},
          { property: 'og:description', content: SEOInfo.description},
        ]}
        link={[
            { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
        ]}
    >
        <script type="application/ld+json">
            {`{
            "@context" : "https://schema.org",
            "@type" : "LocalBusiness",
            "address" : {
                "@type" : "PostalAddress",
                "streetAddress" : "119 N. Markley St",
                "addressLocality" : "Greenville", 
                "addressRegion" : "SC",
                "postalCode" : "29601",
                "addressCountry" : "US"
            },
            "url" : "https://www.orangebees.com",
            "geo" : {
                "@type" : "GeoCoordinates",
                "latitude" : "34.84527461017126",
                "longitude" : "-82.41057081406902"
            },
            "telephone" : "+18645161525",
            "keywords" : "enterprise, software, solutions, artificial, intelligence, development"
            }`}
        </script>
    </Helmet>
);