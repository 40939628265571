import "./caseStudy.scss";

import Footer from "../../components/footer"
import Form from "../../components/contact/form"
import Header from "../../components/header"
import Hero from "../../components/case-studies/hero"
import Layout from "../../components/layout"
import PortfolioCaseStudyCard from "../../components/portfolio/portfolioCaseStudyCard"
import React from "react"
import Seo from "../../components/seo2"
import ResultsHero from '../../components/case-studies/resultsHero';
import CaseStudyStatement from "./caseStudyStatement";

import { getSEOByIdentifier } from "../../services/seo.service";
import { getStaticContentByIdentifier } from "../../services/misc.service";
import { getStaticImageByIdentifier } from "../../services/image.service";

import { CaseStudyTalkingPoints } from "./caseStudyTalkingPoints";
import CaseStudyFeatures from "./caseStudyFeatures";
import { CaseStudyStatCards } from "./caseStudyStatCards";

const caseStudyPage = (props) => {

  const caseStudy = props.pageContext.caseStudy
  const caseStudies = props.pageContext.caseStudies
  const visibleCaseStudies = caseStudies.filter((x) => x.id !== caseStudy.id)

  const caseStudyStaticContent = caseStudy.staticContents
  const caseStudyStaticImages = caseStudy.staticImages
  const caseStudyTalkingPoints = caseStudy.talkingPoints
  const caseStudyFeatures = caseStudy.features
  const caseStudyStatCards = caseStudy.statCards

  const caseStudyName = caseStudy.cardCTALink.toUpperCase()

  const SEOInfo = props.pageContext.SEOInfo

  const setFlexCaseStudyImage = () => {
    return caseStudyName.toLocaleLowerCase() === 'itc';
  }

  return (
    <Layout>
      <Seo SEOInfo={getSEOByIdentifier(caseStudy.cardCTALink, SEOInfo)} />
      <Header />
      <Hero client={caseStudy.client} link={caseStudy.link} title={caseStudy.cardTitle} description={caseStudy.cardText} logo={process.env.filesBaseUrl + caseStudy.clientIcon.publicUrl} image={getStaticImageByIdentifier(caseStudyName + '.hero.image', caseStudyStaticImages)} />
      <CaseStudyTalkingPoints caseStudyTalkingPoints={caseStudyTalkingPoints} />
      <CaseStudyStatement caseStudyName={caseStudyName} caseStudyStaticContent={caseStudyStaticContent} caseStudyStaticImages={caseStudyStaticImages} />
      <div className="case-study-features margin-b-5 margin-t-4">
        <div className="padding-r-4 padding-l-4">
          <h2 className="margin-b-0">{getStaticContentByIdentifier(caseStudyName + ".subtitle2", caseStudyStaticContent)}</h2>
          <CaseStudyFeatures caseStudyFeatures={caseStudyFeatures} />
          <div className={`case-study-features-image margin-t-6 ${setFlexCaseStudyImage() ? 'case-study-features-image-flex' : ''}`}>
            <img className={`${setFlexCaseStudyImage() ? 'case-study-features-image-flex-image' : 'case-study-features-image-no-flex'}`} src={getStaticImageByIdentifier(caseStudyName + ".image2", caseStudyStaticImages)} alt={caseStudyName} />
          </div>
        </div>
      </div>
      <div className="results margin-b-5 margin-t-6">
        <div className="padding-r-4 padding-l-4">
          <h2 className="margin-b-3">{getStaticContentByIdentifier(caseStudyName + ".subtitle3", caseStudyStaticContent)}</h2>
          <p>
            {getStaticContentByIdentifier(caseStudyName + ".content2", caseStudyStaticContent)}
          </p>
          <CaseStudyStatCards caseStudyStatCards={caseStudyStatCards} />
        </div>
      </div>
      <ResultsHero resultsImage={getStaticImageByIdentifier(caseStudyName + ".image3", caseStudyStaticImages)} />
      {
        caseStudies.length > 0 ?
          (
            <div className="more-case-studies margin-t-5">
              <div className="padding-r-4 padding-l-4">
                <h2 className="margin-b-0 margin-t-6 text-center">More Case Studies</h2>
                <div className="case-studies">
                  {
                    visibleCaseStudies.map((x, i) => (
                      <div key={i}><PortfolioCaseStudyCard cardInfo={x} small={true} /></div>
                    ))
                  }
                </div>
              </div>
            </div>
          ) : null
      }
      <div className="contact padding-t-6 padding-b-6">
        <div className="padding-r-4 padding-l-4 text-center">
          <h2 className="margin-b-5">
            {getStaticContentByIdentifier(caseStudyName + ".contactCTA", caseStudyStaticContent)}
          </h2>
          <div>
            <Form outside={true}></Form>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default caseStudyPage