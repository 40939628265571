import React from 'react';


export const CaseStudyTalkingPoints = ({ caseStudyTalkingPoints }) => {

  return (
    <>
      <div className="case-study-talking-points margin-t-5 margin-b-5">
        <div>
          {
            caseStudyTalkingPoints.map((x, i) =>
            (
              <div key={i} className="padding-l-2 padding-r-2">
                <h3>
                  {x.title}
                </h3>
                <p>{x.body}</p>
              </div>
            )
            )
          }
        </div>
      </div>
    </>
  )
}
