import "./hero.scss"

import React from "react"
import backArrow from '../../../static/images/icon-back-arrow.svg'

export default (props) => {
  return (
    <section className="case-study-hero">
      <div>
        <div className="link-bar">
          <a href="/portfolio" className="return-link row"><img className="back-arrow" alt="return arrow for link" src={backArrow}/>BACK TO PORTFOLIO</a>
        </div>
        <div className="text-center">
          <div className="content padding-l-2 padding-r-2">
            {/* <div className="logo">
              <img
                src={props.logo}
                alt="logo"
              />
            </div>
            <div className="client margin-b-4">
              <a href={props.link} rel="noopener noreferrer" target="_blank">{props.client}</a>
            </div> */}
            <h1 className="mt-2">{props.title}</h1>
            <p className="description margin-b-5">{props.description}</p>
          </div>
          <div className="hero-image" style={{ backgroundImage: `url(${props.image})` }}></div>
        </div>
      </div>
    </section>
  )
}
