import "./portfolioCaseStudyCard.scss"
import React from 'react';

export default (props) => {
  const cardStyle = {
    backgroundColor: props.cardInfo.backgroundColor,
    color: props.cardInfo.mainFontColor,
    backgroundSize: props.small ? '200%' : '100%',
    backgroundImage: `url(${process.env.filesBaseUrl + props.cardInfo.backgroundImage.publicUrl})`,
  }
  return (
    <React.Fragment>
      <a className="portfolio-card-link" href={`/case-studies/${props.cardInfo.cardCTALink}`}>
        <div className={props.small ? "portfolio-card small" : "portfolio-card"} style={cardStyle}>
          <div
            className="veil"
            style={{
              background: `linear-gradient(90deg, ${props.cardInfo.veilBackgroundColorLeft} 0%, ${props.cardInfo.veilBackgroundColorRight} 100%)`,
              opacity: props.cardInfo.veilBackgroundOpacity,
            }}></div>
          <div className="row portfolio-row">
            <div className={!props.small ? "portfolio-column-one padding-l-2 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" : "portfolio-column-one-small-card padding-l-2 col-xs-12 col-sm-12"} >
              <div className="padding-l-3 background-wrapper" style={{ 'backgroundColor': props.cardInfo.backgroundColor.slice(0, 7) + 'E6' }}>
                {/* <div className="port-card-client-header margin-t-2"><img className="port-card-client-icon" src={process.env.filesBaseUrl+props.cardInfo.clientIcon.publicUrl} alt="Client Icon" /> <span className="port-card-client-name text-xs">{props.cardInfo.client}</span></div> */}
                <div>
                  <h3 className="port-card-title">{props.cardInfo.cardTitle}</h3>
                  {
                    !props.small ?
                    (
                      <div className="port-card-text text-medium">{props.cardInfo.cardText}</div>
                      ) : null
                  }
                </div>
                <div className="port-card-button-div margin-b-2">
                  <button className={props.small ? "port-card-button-small-card margin-b-0" : "port-card-button margin-b-0"} >{props.cardInfo.cardCTAText} <img className="port-card-button-icon" alt="portfolio button icon" src={process.env.filesBaseUrl + props.cardInfo.cardCTAIcon.publicUrl} /></button>
                </div>
              </div>
            </div>
            {
              !props.small ?
                (
                  <div className="port-card-right-image col-xs-0 col-sm-0 col-md-6 col-lg-6 col-xl-6">
                    <img className="port-card-right-image-tag" style={{ right: props.cardInfo.cardCTALink.toLocaleLowerCase() === 'itc' ? 0 : 30}} alt="portfolio card" src={process.env.filesBaseUrl + props.cardInfo.cardMainImage.publicUrl} />
                  </div>
                ) : (
                  <div className="port-card-right-image-small-card col-xs-0 col-sm-0 col-md-6 col-lg-6 col-xl-6">
                    <img className="port-card-right-image-tag-small-card" alt="portfolio card" src={process.env.filesBaseUrl + props.cardInfo.cardMainImage.publicUrl} />
                  </div>
                )
            }
          </div>
        </div>
      </a>
    </React.Fragment>
  )
}