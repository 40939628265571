import React from 'react';

import { getStaticContentByIdentifier } from "../../services/misc.service";
import { getStaticImageByIdentifier } from "../../services/image.service";

export default ({ caseStudyName, caseStudyStaticContent, caseStudyStaticImages }) => {
    const stringQuote = caseStudyName + ".banner.quote";
    return (
        <>
            {
                caseStudyStaticContent.some(e => e.identifier === stringQuote) ? (
                    <div className="statement padding-t-5 padding-b-5 margin-b-5">
                        <div className="padding-r-4 padding-l-4 text-center">
                            <p className="quote margin-b-4">
                                {getStaticContentByIdentifier(caseStudyName + ".banner.quote", caseStudyStaticContent)}
                            </p>
                            <div className="client">
                                {getStaticContentByIdentifier(caseStudyName + ".banner.client", caseStudyStaticContent)}
                            </div>
                            <div className="job">
                                {getStaticContentByIdentifier(caseStudyName + ".banner.job", caseStudyStaticContent)}
                            </div>
                        </div>
                    </div>
                ) : (
                    null
                )
            }
            <div className="process margin-b-5">
                <div className="padding-r-4 padding-l-4">
                    <div className="process-header">
                        <div>
                            <h2 className="margin-b-0">
                                {getStaticContentByIdentifier(caseStudyName + ".subtitle1", caseStudyStaticContent)}
                            </h2>
                            <p className="padding-t-4">
                                {getStaticContentByIdentifier(caseStudyName + ".content1", caseStudyStaticContent)}
                            </p>
                        </div>
                        <div className="image-container margin-t-3">
                            <img src={getStaticImageByIdentifier(caseStudyName + ".image1", caseStudyStaticImages)} alt={caseStudyName} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}